import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { formatNumber } from '../../utils/helper';
import { Dropdown } from 'react-bootstrap';
import { setSelectedFiatPair } from '../../redux/coinSlice';

export default function SellForm() {
    const dispatch = useDispatch();
    const [tradeData, setTradeData] = useState({
        coin1: '',
        coin2: '',
        rate: 0,
    });

    const { coin1, coin2, rate } = tradeData;
    const [coin1DropdownOpen, setCoin1DropdownOpen] = useState(false);
    const [coin2DropdownOpen, setCoin2DropdownOpen] = useState(false);
    const handleCoin1Toggle = (isOpen) => {
        setCoin1DropdownOpen(isOpen);
        if (isOpen) {
            setCoin2DropdownOpen(false);
        }
    };

    const handleCoin2Toggle = (isOpen) => {
        setCoin2DropdownOpen(isOpen);
        if (isOpen) {
            setCoin1DropdownOpen(false);
        }
    };

    const { tradePairs, selectedFiatPair } = useSelector(state => state.coin);

    const filteredCoin2Pairs = tradePairs.filter(pair =>
        pair.coin1.shortCode === selectedFiatPair?.coin1?.shortCode &&
        pair.pairType === "fiat"
    );

    const filteredCoin1Pairs = tradePairs.filter(pair =>
        pair.coin2.shortCode === selectedFiatPair?.coin2?.shortCode &&
        pair.pairType === "fiat"
    );

    const defaultFiatPair = selectedFiatPair ||
        (tradePairs && tradePairs.length > 0 && tradePairs.find(pair => pair.pairType === 'fiat' && pair.default)) ||
        (tradePairs && tradePairs[0]);

    const [adjustedCoin1Price, setAdjustedCoin1Price] = useState(0);
    const [adjustedCoin2Price, setAdjustedCoin2Price] = useState(0);

    const fetchAdjustedPrice = async (symbol) => {
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USDT`);
            return response.data['USDT'];
        } catch (error) {
            console.error('Error fetching prices:', error);
            return 0;
        }
    };

    const updatePrices = async () => {
        const fiatCoin1Price = await fetchAdjustedPrice(defaultFiatPair?.coin1?.shortCode);
        const fiatCoin2Price = await fetchAdjustedPrice(defaultFiatPair?.coin2?.shortCode);
        setAdjustedCoin1Price(fiatCoin1Price);
        setAdjustedCoin2Price(fiatCoin2Price);

        const originalRate = fiatCoin1Price / fiatCoin2Price;
        const adjustedRate = originalRate + (originalRate * 0.01);
        setTradeData(prevState => ({
            ...prevState,
            rate: adjustedRate.toFixed(6),
        }));
    };

    useEffect(() => {
        updatePrices();
    }, [defaultFiatPair]);

    const debouncedCalculateCoin2Value = useCallback(debounce((coin1Amount) => {
        if (coin1Amount && adjustedCoin1Price && adjustedCoin2Price) {
            const coin2AmountOriginal = (coin1Amount * adjustedCoin1Price) / adjustedCoin2Price;
            const coin2Amount = coin2AmountOriginal + (coin2AmountOriginal * 0.01);
            setTradeData(prevState => ({
                ...prevState,
                coin2: coin2Amount.toFixed(2),
            }));
        }
    }, 1000), [adjustedCoin1Price, adjustedCoin2Price]);

    useEffect(() => {
        if (coin1) {
            debouncedCalculateCoin2Value(parseFloat(coin1));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: ''
            }));
        }
        return () => {
            debouncedCalculateCoin2Value.cancel();
        };
    }, [coin1, debouncedCalculateCoin2Value]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const numericValue = value.replace(/,/g, '');
        setTradeData({
            ...tradeData,
            [id]: numericValue
        });
    };

    const handleCoinSelect = (coin, pair) => {
        dispatch(setSelectedFiatPair(pair));
    };

    return (
        <form>
            <div className="text-center mx-2">
                <h5 className="font-bold">{`Sell ${defaultFiatPair?.coin2.shortCode} with ${defaultFiatPair?.coin1.shortCode}`}</h5>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin1">Pay</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin1'
                            name='coin1'
                            placeholder="0"
                            value={coin1}
                            onChange={handleInputChange}
                            step="any"
                        />
                        {filteredCoin2Pairs.length === 1 ? (
                            <button
                                className="input-group-text d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCoinSelect('coin2', filteredCoin2Pairs[0]);
                                    setCoin2DropdownOpen(false);
                                }}
                            >
                                <img
                                    alt={filteredCoin2Pairs[0].coin2.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={filteredCoin2Pairs[0].coin2.icon}
                                />
                                {filteredCoin2Pairs[0].coin2.shortCode}
                            </button>
                        ) : (
                            <Dropdown
                                className="input-group-text"
                                show={coin2DropdownOpen}
                                onToggle={handleCoin2Toggle}
                            >
                                <Dropdown.Toggle
                                    variant="basic"
                                    id="coin-dropdown"
                                    className="d-flex align-items-center cursor-pointer"
                                >
                                    <img
                                        alt={selectedFiatPair?.coin2?.name}
                                        className="img-fluid coin-symbol me-1"
                                        src={selectedFiatPair?.coin2?.icon}
                                    />
                                    {selectedFiatPair?.coin2?.shortCode}
                                </Dropdown.Toggle>
                                {filteredCoin2Pairs.length > 0 && (
                                    <Dropdown.Menu>
                                        {filteredCoin2Pairs.map(pair => (
                                            <Dropdown.Item
                                                as="button"
                                                key={pair.coin2.shortCode}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleCoinSelect('coin2', pair);
                                                    setCoin2DropdownOpen(false);
                                                }}
                                            >
                                                <img
                                                    alt={pair.coin2.name}
                                                    className="img-fluid coin-symbol me-1"
                                                    src={pair.coin2.icon}
                                                />
                                                {pair.coin2.shortCode}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin2">Receive</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            id='coin2'
                            name='coin2'
                            placeholder="0"
                            value={coin2 && formatNumber(parseFloat(coin2))}
                            step="any"
                            readOnly
                        />
                        {filteredCoin1Pairs.length === 1 ? (
                            <button
                                className="input-group-text d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCoinSelect('coin1', filteredCoin1Pairs[0]);
                                    setCoin1DropdownOpen(false);
                                }}
                            >
                                <img
                                    alt={filteredCoin1Pairs[0].coin1.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={filteredCoin1Pairs[0].coin1.icon}
                                />
                                {filteredCoin1Pairs[0].coin1.shortCode}
                            </button>
                        ) : (
                            <Dropdown
                                className="input-group-text"
                                show={coin1DropdownOpen}
                                onToggle={handleCoin1Toggle}
                            >
                                <Dropdown.Toggle
                                    variant="basic"
                                    id="coin-dropdown"
                                    className="d-flex align-items-center cursor-pointer"
                                >
                                    <img
                                        alt={selectedFiatPair?.coin1?.name}
                                        className="img-fluid coin-symbol me-1"
                                        src={selectedFiatPair?.coin1?.icon}
                                    />
                                    {selectedFiatPair?.coin1?.shortCode}
                                </Dropdown.Toggle>
                                {filteredCoin1Pairs.length > 0 && (
                                    <Dropdown.Menu>
                                        {filteredCoin1Pairs.map(pair => (
                                            <Dropdown.Item
                                                as="button"
                                                key={pair.coin1.shortCode}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleCoinSelect('coin1', pair);
                                                    setCoin1DropdownOpen(false);
                                                }}
                                            >
                                                <img
                                                    alt={pair.coin1.name}
                                                    className="img-fluid coin-symbol me-1"
                                                    src={pair.coin1.icon}
                                                />
                                                {pair.coin1.shortCode}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <button type="button" className="btn btn-info w-100 mb-2" disabled={coin1 <= 0}>Get Quote</button>
            </div>
        </form>
    );
}
import React, { useState, useEffect } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { useTextClass } from '../hooks/useTextClass';
import { FaCopy } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function BankDeposit() {
    const { textClass, btnClass, getModeClass } = useTextClass();
    const userDetails = useSelector((state) => state.user.userDetails);
    const [bankDetails, setBankDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [copiedField, setCopiedField] = useState(null);

    useEffect(() => {
        if (!userDetails.isKyc) {
            setLoading(false);
            return;
        }
        initiateBankDeposit();
    }, [userDetails.isKyc]);

    const initiateBankDeposit = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/wallet/fiat-deposit/initiate`,
                {}, // Send an empty object for body if no payload is needed
                { withCredentials: true } // Move withCredentials to options
            );
            setBankDetails(response.data.bankDetails);
            setError(null);
        } catch (err) {
            setError('Failed to get bank details. Please try again later.');
            console.error('Error initiating bank deposit:', err);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text, field) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000); // Reset after 2 seconds
    };

    if (loading) {
        return <div className="text-center mt-5">Loading...</div>;
    }

    if (!userDetails.isKyc) {
        return (
            <div className='page'>
                <div className="container mt-4">
                    <Alert variant="warning">
                        <h4>KYC Required</h4>
                        <p>You need to complete KYC verification before making bank deposits.</p>
                        <Link to="/client/kyc" className={`btn ${btnClass} mt-2`}>
                            Complete KYC
                        </Link>
                    </Alert>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className='page'>
                <div className="container mt-4">
                    <Alert variant="danger">
                        <h4>Error</h4>
                        <p>{error}</p>
                    </Alert>
                </div>
            </div>
        );
    }

    return (
        <div className='page'>
            <div className="container">
                <div className="row  my-4">
                    <div className="col-12">
                        <h2 className={textClass}>Bank Deposit</h2>
                        <Card className={`mt-4 ${getModeClass}`}>
                            <Card.Body>
                                <h4 className={textClass}>Bank Account Details</h4>
                                <p className={textClass}>
                                    Please use the following details to make your deposit. Don't forget to use the reference number provided.
                                </p>
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {bankDetails && (
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Bank Name:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.bankName}</span>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className={`btn btn-sm ${btnClass}`}
                                                                onClick={() => copyToClipboard(bankDetails.bankName, 'bankName')}
                                                            >
                                                                <FaCopy /> {copiedField === 'bankName' && <span className="ms-1">Copied</span>}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Account Holder:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.accountHolder}</span>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className={`btn btn-sm ${btnClass}`}
                                                                onClick={() => copyToClipboard(bankDetails.accountHolder, 'accountHolder')}
                                                            >
                                                                <FaCopy /> {copiedField === 'accountHolder' && <span className="ms-1">Copied</span>}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Account Number:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.accountNumber}</span>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className={`btn btn-sm ${btnClass}`}
                                                                onClick={() => copyToClipboard(bankDetails.accountNumber, 'accountNumber')}
                                                            >
                                                                <FaCopy /> {copiedField === 'accountNumber' && <span className="ms-1">Copied</span>}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Branch Code:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.branch}</span>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className={`btn btn-sm ${btnClass}`}
                                                                onClick={() => copyToClipboard(bankDetails.branch, 'branchCode')}
                                                            >
                                                                <FaCopy /> {copiedField === 'branch' && <span className="ms-1">Copied</span>}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Reference:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.reference}</span>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className={`btn btn-sm ${btnClass}`}
                                                                onClick={() => copyToClipboard(bankDetails.reference, 'reference')}
                                                            >
                                                                <FaCopy /> {copiedField === 'reference' && <span className="ms-1">Copied</span>}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <strong className={textClass}>Account Type:</strong>
                                                            <span className={`ms-2 ${textClass}`}>{bankDetails.type}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <Alert variant="info" className="mt-4">
                                    <strong>Important:</strong>
                                    <ul className="mb-0">
                                        <li>Always use the provided reference number when making deposits</li>
                                        <li>Deposits typically reflect within 24-48 hours</li>
                                        <li>Minimum deposit amount: R100</li>
                                        <li>For assistance, please contact support</li>
                                    </ul>
                                </Alert>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
} 
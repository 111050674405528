import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { LuUserCircle } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { setIsLogged, setUserDetails, toggleMode } from '../../redux/userSlice';
import { setCoinData, setTradePairs, setSelectedCryptoPair, setSelectedFiatPair } from '../../redux/coinSlice';
import axios from 'axios';
import { Nav, Navbar } from 'react-bootstrap';
import useFetchAllCoins from '../../hooks/useFetchAllCoins';
import useTradePairs from '../../hooks/useTradePairs';
import { useTextClass } from '../../hooks/useTextClass';

export default function Header() {
    const [isFixed, setIsFixed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedCryptoPair, selectedFiatPair } = useSelector(state => state.coin);
    const { isLogged, userDetails } = useSelector((state) => state.user);
    const { mode, textClass, getModeClass } = useTextClass();
    const location = useLocation();

    const { coins } = useFetchAllCoins();
    useEffect(() => {
        if (coins) {
            dispatch(setCoinData(coins));
        }
    }, [coins, dispatch]);

    const { tradePairs } = useTradePairs();
    // Fetch coins and update Redux state
    useEffect(() => {
        if (tradePairs.length > 0) {
            dispatch(setTradePairs(tradePairs));
            const defaultFaitPair = tradePairs.find(pair => pair.pairType === 'fiat' && pair.default);
            const defaultCryptoPair = tradePairs.find(pair => pair.pairType === 'crypto' && pair.default);

            // Check the current path
            if (location.pathname === '/ae') {
                // Find the pair where coin1 is BTC and coin2 is AED
                const selectedPair = tradePairs.find(
                    (pair) => pair?.coin1?.shortCode === 'AED' && pair.coin2?.shortCode === 'BTC'
                );

                // Dispatch setSelectedPairs if the pair is found
                if (selectedPair) {
                    dispatch(setSelectedFiatPair(selectedPair));
                } else {
                    dispatch(setSelectedFiatPair(defaultFaitPair));
                }
            }
            if (!selectedCryptoPair) {
                dispatch(setSelectedCryptoPair(defaultCryptoPair));
            }
            if (!selectedFiatPair) {
                dispatch(setSelectedFiatPair(defaultFaitPair));
            }
        }
    }, [tradePairs, dispatch, location.pathname, selectedCryptoPair, selectedFiatPair]);

    const logOut = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/logout`, { withCredentials: true });
            if (response) {
                dispatch(setIsLogged(false));
                dispatch(setUserDetails({}));
                navigate('/');
                setExpanded(false); // Close the navbar after logging out
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = () => {
        setExpanded(false); // Close the navbar after clicking a link
    };

    return (
        <header className={`header-section header-section--style2 ${isFixed ? 'header-fixed' : ''}`}>
            <div className="header-bottom">
                <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} collapseOnSelect expand="lg" className={`bg-body-tertiary ${getModeClass}`}>
                    <div className="container">
                        <Navbar.Brand to="/" as={Link} onClick={handleNavLinkClick}>
                            {mode === 'dark' ?
                                <img className="dark logo" src="/assets/images/logo-dark.png" alt="logo" />
                                :
                                <img className="dark logo" src="/assets/images/logo-light.png" alt="logo" />
                            }

                        </Navbar.Brand>
                        <Navbar.Toggle className={`${getModeClass}`} aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link className={textClass} to="/about-us" as={Link} onClick={handleNavLinkClick}>About Us</Nav.Link>
                                <Nav.Link className={textClass} to="/buy-and-sell" as={Link} onClick={handleNavLinkClick}>Buy & Sell</Nav.Link>
                                <Nav.Link className={textClass} to="/institutions" as={Link} onClick={handleNavLinkClick}>Institutions</Nav.Link>
                                <Nav.Link className={textClass} to="/otc" as={Link} onClick={handleNavLinkClick}>OTC Desk</Nav.Link>
                                <Nav.Link className={textClass} to="/contact-us" as={Link} onClick={handleNavLinkClick}>Contact Us</Nav.Link>
                            </Nav>
                            <Nav className='me-5 mt-3'>
                                {isLogged ?
                                    <>
                                        {userDetails?.role === "admin" ?
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={textClass}>
                                                        Manage Users
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/users" as={Link} onClick={handleNavLinkClick}>Users</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/users-kyc" as={Link} onClick={handleNavLinkClick}>Users KYC</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/admins" as={Link} onClick={handleNavLinkClick}>Admins</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={textClass}>
                                                        Manage Coins
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/coins" as={Link} onClick={handleNavLinkClick}>Coins</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/coins/trade-pairs" as={Link}>Trade Pairs</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            :
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={textClass}>
                                                        Wallets
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="client/dashboard" as={Link} onClick={handleNavLinkClick}>Overview</Dropdown.Item>
                                                        <Dropdown.Item to="/client/deposit" as={Link} onClick={handleNavLinkClick}>Crypto Deposit</Dropdown.Item>
                                                        <Dropdown.Item to="/client/bank-deposit" as={Link} onClick={handleNavLinkClick}>Fiat Deposit</Dropdown.Item>
                                                        <Dropdown.Item to="/client/buy-crypto" as={Link} onClick={handleNavLinkClick}>Buy Crypto</Dropdown.Item>
                                                        <Dropdown.Item to="/client/withdraw" as={Link} onClick={handleNavLinkClick}>Withdraw</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={textClass}>
                                                        History
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {/* <Dropdown.Item href="/" onClick={handleNavLinkClick}>Trade History</Dropdown.Item> */}
                                                        <Dropdown.Item to="/client/history/transactions" as={Link} onClick={handleNavLinkClick}>Transactions History</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                        }
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" id="dropdown-basic" className={`${userDetails?.username ? '' : 'mt-2'}`}>
                                                <div className={textClass}>{userDetails?.avatar ? <img className='img-fluid rounded-circle' style={{ height: '30px' }} src={userDetails?.avatar} alt={userDetails?.FirstName} /> : <LuUserCircle size={20} />} {`${userDetails?.LastName}`}</div>
                                                <span className={textClass}>{userDetails?.username && userDetails?.username}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item>}
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item>}
                                                <Dropdown.Item to="/client/profile-update" as={Link} onClick={handleNavLinkClick}>Profile</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { logOut(); handleNavLinkClick(); }}>Sign Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <Link className={`nav-link ${textClass}`} to="/signin" onClick={handleNavLinkClick}>Sign In</Link>
                                        <Nav.Link to="/signup" className="trk-btn trk-btn--border trk-btn--primary" as={Link} onClick={handleNavLinkClick}><span>Register</span></Nav.Link>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                        <div className="lightdark-switch d-none d-md-block">
                            <span className="switch-btn dark-switcher" id="btnSwitch" onClick={() => dispatch(toggleMode())}>
                                {mode === 'light' ? <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/icon/moon.svg`} alt="light-dark-switchbtn" className="swtich-icon" /> : <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/icon/sun.svg`} alt="light-dark-switchbtn" className="swtich-icon" />}
                            </span>
                        </div>
                    </div>
                </Navbar>
            </div >
        </header >
    );
}
